<template>
    <UiCoreSimpleCard v-editable="blok" :blok="blok" class="ui-core-simple-card" data-test="ui-core-simple-card" />
</template>

<script setup lang="ts">
import type { ICoreSimpleCard } from '~ui/types/components/CoreSimpleCard';

defineProps<{ blok: ICoreSimpleCard }>();
</script>

<style scoped lang="postcss"></style>
