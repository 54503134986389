<template>
    <UiCoreSimpleRichText
        v-editable="blok"
        class="sb-core-simple-rich-text"
        data-test="sb-core-simple-rich-text"
        :blok="blok"
    />
</template>

<script setup lang="ts">
import type { ICoreSimpleRichText } from '~ui/types/components/CoreSimpleRichText';

defineProps<{ blok: ICoreSimpleRichText }>();
</script>

<style scoped lang="postcss">
.sb-core-simple-rich-text {
    :deep(ul) {
        li {
            span:first-child.css-list-checkmarks:after {
                @apply left-[-1.4rem] h-4 w-4 text-[#5a5e6b] pr-4 top-0 content-[url(~/assets/images/check.svg)];
            }

            p {
                @apply mb-2;
            }
        }
    }
    :deep(.rich-text) {
        p {
            @apply text-[#5a5e6b];
        }
        a {
            @apply underline text-[#0000ee];

            &:has(.text-primary) {
                @apply decoration-primary;
            }

            &:has(.text-secondary) {
                @apply decoration-secondary;
            }

            &:has(.text-tertiary) {
                @apply decoration-tertiary;
            }
        }

        h1 {
            @apply text-[2.25rem];
        }
        h2 {
            @apply text-[2rem];
        }
        h3 {
            @apply text-[1.75rem];
        }
        h4 {
            @apply text-[1.5rem];
        }
        h5 {
            @apply text-[1.25rem];
        }
        h6 {
            @apply text-[1.125rem];
        }
    }
}
</style>
