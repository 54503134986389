<template>
    <section v-if="blok" v-editable="blok" class="hero-card-layout" :style="layoutStyle">
        <template v-if="blok.header.length">
            <div
                v-for="headerComponent in blok.header"
                :key="headerComponent._uid"
                class="hero-card-layout__header-item"
            >
                <Component :is="headerComponent.component" :blok="headerComponent" />
            </div>
        </template>
        <div v-if="blok.card_content.length || blok.footer_images.length" class="hero-card-layout__content container">
            <div v-if="blok.card_content.length" class="hero-card-layout__content-card" :style="contentCardStyles">
                <template v-for="slot in blok.card_content" :key="slot._uid">
                    <Component :is="slot.component" :blok="slot" />
                </template>
            </div>

            <div v-if="blok.footer_images.length" class="hero-card-layout__footer">
                <div v-for="image in blok.footer_images" :key="image._uid" class="footer-img-wrap">
                    <NuxtImg
                        :src="image.filename"
                        provider="storyblok"
                        format="webp"
                        loading="lazy"
                        densities="x1"
                    ></NuxtImg>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IHeroCardLayout } from '~ui/types/components/HeroCardLayout';

const { blok } = defineProps<{ blok: IHeroCardLayout }>();

const layoutStyle = computed(() => {
    return {
        backgroundColor: blok?.card_outer_background?.color ? blok.card_outer_background.color : null,
    };
});
const contentCardStyles = computed(() => {
    return {
        'row-gap': blok.cardContentComponentGaps || null,
        alignItems: blok?.card_content_align ? blok.card_content_align : null,
    };
});
</script>

<style scoped lang="postcss">
.hero-card-layout {
    @apply flex flex-col;
    &__content {
        @apply px-4 mx-auto max-md:bg-[#ffffff];
    }
    &__content-card {
        @apply w-full flex flex-col bg-white;
        @media screen(md) {
            @apply relative -top-[68px] -mb-[68px] px-5 bg-[#f9f9f9];
            box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.37);
        }
    }
    &__footer {
        @apply hidden md:flex relative flex-wrap items-center justify-between py-10 px-3;
        .footer-img-wrap {
            @apply w-[15%] lg:w-auto;
        }
        :deep(img) {
            @apply block w-full h-auto;
        }
    }
}
</style>
