<template>
    <UiHeroCardLayoutContainer :blok="blok" />
</template>

<script setup lang="ts">
import type { IHeroCardLayout } from '~ui/types/components/HeroCardLayout';

defineProps<{ blok: IHeroCardLayout }>();
</script>

<style scoped lang="postcss">
.hero-card-layout {
    :deep(.hero-card-layout-header) {
        @apply bg-center min-h-[260px] md:min-h-[360px] bg-no-repeat p-0;
        background-position-y: 0;

        .content-container {
            @apply max-w-[1120px] pt-2;

            .title-wrap,
            .subtitle-wrap {
                @apply px-6;
            }
        }
    }

    :deep(.hero-card-layout__content) {
        @apply max-w-[1120px] px-0 lg:px-4;
        .hero-card-layout__content-card {
            @apply px-0 max-w-[1120px] -top-[100px] bg-inherit gap-y-8 shadow-none;

            .ui-simple-rich-text {
                @apply bg-white py-4 px-8;
            }
        }
    }
}
</style>
