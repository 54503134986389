<template>
    <SbFooter :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss">
#sbFooter {
    @apply px-4 pt-8 pb-4;

    .footer-container {
        @apply md:grid grid-cols-[50%_50%] grid-rows-[50%_50%] gap-2;
    }

    .footer-accordions {
        @apply hidden;
    }

    .overview {
        @apply max-md:flex max-md:justify-center;
        @apply md:col-[1_/_2] md:row-[1_/_2] md:mb-0;

        .rich-text {
            img {
                @apply w-[120x] h-[43px];
            }
        }
    }

    .links-col-title {
        @apply m-0;
    }

    .links-lists-container {
        @apply block order-2;
    }

    .links {
        @apply col-[2_/_3] row-[1_/_3] flex flex-col justify-between gap-2;
        .links-list {
            @apply flex max-md:flex-col max-md:text-center md:order-2;
            .links-item {
                @apply md:pr-3 text-[0.7rem] leading-5;

                a {
                    @apply text-xs;
                    &:hover {
                        @apply underline text-black;
                    }
                }
            }
        }
    }

    .copyright {
        @apply text-[0.7rem] flex items-end max-md:justify-center;
        @apply md:col-[1_/_2] md:row-[2_/_3];
    }

    .socials {
        @apply flex gap-2 order-1 max-md:justify-center;
        .socials-text {
            @apply hidden;
        }
    }
}
</style>
