<template>
    <JPTestimonial v-editable="blok" :blok="blok" :airport="airport" data-test="jp-testimonial" />
</template>

<script lang="ts" setup>
import JPTestimonial from '~/components/JPTestimonial.vue';
import type { IAirportEntity } from '~ui/types';
import type { ITestimonial } from '~ui/types/components/Jetpark/Testimonial';

defineProps<{ blok: ITestimonial; airport: IAirportEntity }>();
</script>

<style scoped lang="postcss"></style>
