<template>
    <div class="dates-container single-date-container">
        <div class="dates" :class="{ open: calendarShow }" :data-title="datePickerTitle" @click="toggleCalendar(true)">
            <div class="input-group-container has-value">
                <label v-if="!isMobile" :for="`form-input-${name}-date`" class="input-group-title has-icon">
                    {{ label.global }}
                </label>
                <template v-else>
                    <label class="input-group-title time has-icon">{{ label.date }}</label>
                </template>
                <div class="group-inputs">
                    <div
                        class="input-group"
                        :class="{ error: validation ? validation.date.$error : false }"
                        :data-tooltip="tooltip"
                    >
                        <div class="input-icon-prepend">
                            <NuxtIcon name="ri:calendar-2-line" />
                        </div>
                        <input
                            :id="`form-input-${name}-date`"
                            type="text"
                            :name="`${name}_date`"
                            class="tooltip-handle single-date-datepicker-input"
                            :data-tooltip="tooltip"
                            :placeholder="placeholder"
                            :value="data.date ? formatDate(data.date, 'day, dd/mm/yy') : null"
                            readonly
                        />
                        <LazyUiSearchboxComponentsDatePickerElement
                            v-if="calendarShow"
                            :value="data.date"
                            mode="date"
                            expanded
                            :name="name"
                            color="primary"
                            :class="anchor"
                            :min-date="new Date(minDate)"
                            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
                            @toggle-calendar="toggleCalendar"
                            @update:value="onUpdateDate"
                        />
                        <div v-if="tooltip?.length" class="tooltip">{{ tooltip }}</div>
                        <div v-if="validation && validation.$errors?.length" class="error-tooltip">
                            {{ validation.$errors[0].$message }}
                        </div>
                    </div>
                    <div class="input-group" :class="{ error: validation ? validation.time.$error : false }">
                        <label v-if="isMobile" class="input-group-title time-label has-icon">{{ label.time }}</label>
                        <div class="input-icon-prepend">
                            <NuxtIcon name="ri:timer-2-line" />
                        </div>
                        <div class="input-icon-append">
                            <NuxtIcon name="ri:arrow-down-s-line" />
                        </div>
                        <select
                            :id="`form-input-${name}`"
                            :name="name"
                            :value="value.time"
                            class="tooltip-handle time-select-input"
                            :class="[!value.time?.length ? 'text-gray-500' : '']"
                            @change="(event) => onTimeUpdate((event.target as HTMLInputElement).value)"
                        >
                            <option value="" disabled>Select Time</option>
                            <option
                                v-for="timeOption in timeOptions"
                                :key="timeOption.value"
                                :value="timeOption.label"
                                :disabled="isDisabledOption(timeOption.value)"
                            >
                                {{ timeOption.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- <VDatePicker
            v-model="dateVal"
            mode="date"
            expanded
            color="primary"
            :class="anchor"
            :columns="columnCount"
            :rows="rowsCount"
            :min-page="minPage"
            :min-date="new Date()"
            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
            :popover="{ visibility: isMobile && !calendarShow ? 'click' : 'focus' }"
            :locale="{
                id: localeObj.iso,
                firstDayOfWeek: 1,
                masks: { weekdays: 'WW', input: 'WWW, MMM D' },
            }"
            @popover-will-show="toggleCalendar(true)"
            @popover-will-hide="toggleCalendar(false)"
            @popover-did-hide="toggleCalendar(false)"
        >
        </VDatePicker> -->
    </div>
</template>

<script setup lang="ts">
interface ISelectOption {
    label: string;
    value: string;
}

const props: any = defineProps({
    name: { type: String, required: true },
    value: { type: Object, required: false, default: null },
    label: { type: Object, required: false, default: () => ({}) },
    placeholder: { type: String, required: false, default: 'Select Date' },
    datePickerTitle: { type: String, required: false, default: 'Please select a date' },
    tooltip: { type: String, required: false, default: '' },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    minDate: { type: Date, required: false, default: null },
    allowSingleRange: { type: Boolean, required: false, default: false },
});

const data = useState(props.name + '_dates', () => ({
    date: props.value.date,
    time: props.value.time,
}));

const onTimeUpdate = (value) => {
    data.value.time = value;
    emit('update:value', data.value);
};

const onUpdateDate = (value) => {
    data.value.date = value;
    emit('update:value', data.value);
};

const timeOptions = computed(() => {
    const startTime = new Date(Date.UTC(0, 0, 1, 24, 0));
    const endTime = new Date(Date.UTC(0, 0, 2, 23, 59));
    const timeStops: ISelectOption[] = [];

    while (startTime <= endTime) {
        const time = new Date(startTime);
        const hhmm =
            time.getUTCHours().toString().padStart(2, '0') + ':' + time.getUTCMinutes().toString().padStart(2, '0');

        timeStops.push({ label: hhmm, value: hhmm } as ISelectOption);
        startTime.setUTCMinutes(startTime.getUTCMinutes() + 60);
    }

    return timeStops;
});

// Is Mobile?
const isMobile: any = ref(false);

// Resolve Datepicker
const calendarShow: any = ref(false);

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

function toggleCalendar(val: boolean) {
    if (val) {
        calendarShow.value = true;
        document.body.classList.add('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    } else {
        setTimeout(() => (calendarShow.value = false), 200);
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    }
}

const columnCount = ref(2);
const rowsCount = ref(1);

const isDisabledOption = (timeOption: string): boolean => {
    if (!props.minTime) {
        return false;
    }

    const baseDate = `${new Date().getFullYear()}-01-01`;
    const hourMin = timeOption.replace(' PM', '').replace(' AM', '').split(':');
    const min = hourMin[1] || '';
    let hour = hourMin[0] || '';

    if (timeOption.includes('PM') && hour !== '12') {
        hour = `${parseInt(hour) + 12}`;
    } else if (timeOption.includes('AM') && hour === '12') {
        hour = `${parseInt(hour) - 12}`;
    }

    const dateTime1 = new Date(`${baseDate} ${hour}:${min}:00`);
    const dateTime2 = new Date(`${baseDate} ${props.minTime}:00`);

    // Compare the two date-time objects
    return dateTime1 < dateTime2;
};

// Change column count from 1 (mobile) to 2 (desktop/tablet)
function onWidthChange() {
    if (768 < window.innerWidth) {
        columnCount.value = 2;
        rowsCount.value = 1;
        isMobile.value = false;
    } else {
        columnCount.value = 1;
        if (props.apr) rowsCount.value = 12;
        isMobile.value = true;
    }
}

if (!process.server) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}
</script>

<style lang="postcss" scoped>
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
}
.time-label {
    left: 2.5rem !important;
}
.dates-container {
    @apply relative;

    .group-inputs {
        @apply flex;
        .input-group {
            @apply basis-1/2;
        }
    }

    .bar {
        @apply hidden xl:block opacity-0 absolute top-[4.5rem] z-[1] h-[2px] w-24 bg-primary-500 rounded pointer-events-none transition;
        &.right {
            @apply opacity-100 translate-x-[90%];
        }
        &.left {
            @apply opacity-100 -translate-x-3/4;
        }
    }

    .dates {
        @apply flex justify-center items-center w-full;

        .close {
            @apply hidden;
        }

        & + :deep(.vc-container) {
            @apply fixed md:absolute md:top-12 bottom-0 md:bottom-auto left-0 right-0 z-[99] min-w-full max-w-[100vw] md:min-w-0 rounded border-[#e9daf0] border-b-[20px] flex flex-col;
        }

        /*** Mobile Only ***/
        @media (max-width: theme('screens.md')) {
            &.open {
                .input-group-container {
                    @apply hidden;
                }
                @apply fixed top-14 left-0 right-0 bg-white;
                &:before {
                    content: attr(data-title);
                    @apply fixed top-0 left-0 right-0 flex items-center justify-center h-14 py-3 px-4 bg-transparent whitespace-nowrap text-white font-bold border-b border-gray-200 bg-primary-500 text-sm text-center;
                }

                .close {
                    @apply fixed block left-4 top-4 text-white;
                }

                & + :deep(.vc-popover-content-wrapper) {
                    @apply !fixed !top-[5rem] !left-0 !right-0 !bottom-0 !transform bg-white z-[99];

                    .vc-popover-content {
                        @apply !mt-0 rounded-none shadow-none border-b-0;
                    }

                    .vc-container {
                        @apply z-[99];
                    }

                    .calendar-header {
                        @apply hidden;
                    }

                    .vc-pane-header-wrapper {
                        @apply top-0;
                    }

                    .vc-day,
                    .vc-day-content,
                    .vc-highlight {
                        @apply min-w-full;
                        min-height: calc(100vw / 8);
                    }
                }
            }
        }

        :deep(.vc-container) {
            @apply z-[99] flex flex-col min-w-full max-w-[100vw] md:min-w-0 rounded border border-gray-500;
        }

        .time-select-input {
            @apply !rounded-l-none !border-l-2 border-solid !border-[#e7e7e7];
        }
        .single-date-datepicker-input {
            @apply !rounded-r-none;
        }
    }

    :deep(.vc-pane-container) {
        @apply flex flex-col-reverse;

        .vc-pane-header-wrapper {
            @apply top-11;
        }

        .calendar-header {
            @apply py-2 mx-4 bg-transparent rounded-t text-primary-500 font-semibold border-b border-gray-200 text-lg text-center;
        }

        .vc-day,
        .vc-day-content,
        .vc-highlight {
            @apply min-h-[40px] min-w-[40px] rounded-none;
        }
        .vc-highlights + .vc-day-content {
            @apply font-normal;
        }
    }

    :deep(.vc-popover-content) {
        @apply overflow-hidden;

        &.direction-bottom {
            @apply md:mt-1;
        }
    }

    :deep(.vc-popover-caret) {
        @apply hidden;
    }
}
:deep(.vc-primary) {
    --vc-accent-50: theme('colors.primary.50');
    --vc-accent-100: theme('colors.primary.100');
    --vc-accent-200: theme('colors.primary.200');
    --vc-accent-300: theme('colors.primary.300');
    --vc-accent-400: theme('colors.primary.400');
    --vc-accent-500: theme('colors.primary.500');
    --vc-accent-600: theme('colors.primary.600');
    --vc-accent-700: theme('colors.primary.700');
    --vc-accent-800: theme('colors.primary.800');
    --vc-accent-900: theme('colors.primary.900');
}

.dates-container-apr {
    --white: #fff;
    --text-primary-asphalt: #0e242b;
    --text-secondary-grey: #56666b;
    --text-titanium: #fbfcf9;
    --text-titanium-alpha: rgba(251, 252, 249, 0.6);
    --brand-primary-500: #0a475a;
    --brand-primary-light-30: #3b6c7b;
    --brand-secondary-500: #b4e076;
    --brand-secodnary-light-30: #c3e691;
    --surface-brand: #e6edee;
    --surface-brand-light: #f5f8f8;
    --surface-brand-super-light: #fafbfc;
    --text-link: #1776ce;
    --neutral-mid-grey: #b7bdbf;
    --neutral-dark-grey: #869195;
    --neutral-light-grey: #e7e9ea;
    --neutral-soft-grey: #cfd3d5;
    --status-error-red-100: #d41818;
    --status-error-red-8: #fceded;
    --status-success-green-100: #90c652;
    --accent-signal: #de3d68;
    --accent-spotlight: #ffbc00;
    --status-success-text: #38831e;
    --base-borders: #e9e9f0;

    @apply relative;

    .calendar-header {
        @apply hidden;
    }

    .group-inputs {
        @apply flex;
        .input-group {
            @apply basis-1/2;
        }
    }

    .time-label {
        @apply !text-xs !left-4;
    }

    .bar {
        @apply hidden xl:block opacity-0 absolute top-[4.5rem] z-[1] h-[2px] w-24 bg-primary-500 rounded pointer-events-none transition;
        &.right {
            @apply opacity-100 translate-x-[90%];
        }
        &.left {
            @apply opacity-100 -translate-x-3/4;
        }
    }

    .dates {
        @apply flex justify-center items-center w-full;

        .close {
            @apply hidden;
        }

        & + :deep(.vc-container) {
            @apply fixed md:absolute md:top-12 bottom-0 md:bottom-auto left-0 right-0 z-[99] min-w-full max-w-[100vw] md:min-w-0 rounded border-[#e9daf0] border-b-[20px] flex flex-col;
        }

        .input-group input {
            @apply pl-0;
        }

        /*** Mobile Only ***/
        @media (max-width: theme('screens.md')) {
            &.open {
                @apply fixed top-14 left-0 right-0 bg-white z-[10000];
                &:before {
                    content: attr(data-title);
                    color: var(--text-primary-asphalt);
                    border-bottom: 1px solid var(--neutral-light-grey);
                    @apply fixed top-0 left-0 right-0 flex items-center justify-start p-4 h-14 bg-transparent whitespace-nowrap font-semibold bg-white text-xl text-left;
                }
                .input-group-container {
                    @apply hidden;
                }

                .close {
                    @apply fixed block right-4 top-4;
                    color: var(--brand-primary-500);
                }

                & + :deep(.vc-popover-content-wrapper) {
                    @apply !fixed !top-14 !left-0 !right-0 !bottom-0 !transform bg-white z-[99] overflow-auto;

                    .vc-popover-content {
                        @apply !mt-0 rounded-none shadow-none border-none m-0 p-0;
                    }

                    .vc-container {
                        @apply z-[99];
                    }

                    .calendar-header {
                        @apply hidden;
                    }

                    .vc-pane-header-wrapper {
                        @apply hidden;
                    }

                    .vc-day,
                    .vc-day-content,
                    .vc-highlight {
                        @apply min-w-full;
                        min-height: calc(100vw / 8);
                    }
                }
            }
        }

        :deep(.vc-container) {
            @apply z-[99] flex flex-col min-w-full max-w-[100vw] md:min-w-0 rounded border border-gray-500;
        }

        .time-select-input {
            @apply !rounded-l-none !border-l-2 border-solid !pt-[30px] sm:!pt-0;
        }
        .single-date-datepicker-input {
            @apply !rounded-r-none;
        }
    }

    :deep(.vc-pane-container) {
        @apply flex flex-col-reverse;

        .vc-pane-header-wrapper {
            @apply top-0;
        }

        .calendar-header {
            @apply py-2 mx-4 bg-transparent rounded-t text-primary-500 font-semibold border-b border-gray-200 text-lg text-center;
        }

        .vc-day,
        .vc-day-content,
        .vc-highlight {
            @apply min-h-[40px] min-w-[40px] rounded-none;
        }
        .vc-highlights + .vc-day-content {
            @apply font-normal;
        }
    }

    :deep(.vc-popover-content) {
        @apply overflow-hidden;

        &.direction-bottom {
            @apply md:mt-1;
        }
    }

    :deep(.vc-popover-caret) {
        @apply hidden;
    }

    :deep(.vc-primary) {
        --vc-accent-600: #e6edee;
    }
}
:deep(.vc-primary) {
    --vc-accent-50: theme('colors.primary.50');
    --vc-accent-100: theme('colors.primary.100');
    --vc-accent-200: theme('colors.primary.200');
    --vc-accent-300: theme('colors.primary.300');
    --vc-accent-400: theme('colors.primary.400');
    --vc-accent-500: theme('colors.primary.500');
    --vc-accent-600: theme('colors.primary.600');
    --vc-accent-700: theme('colors.primary.700');
    --vc-accent-800: theme('colors.primary.800');
    --vc-accent-900: theme('colors.primary.900');
}
</style>
