<template>
    <NuxtImg
        :src="blok.image.filename"
        :alt="blok.image.alt"
        :title="blok.image.title"
        provider="storyblok"
        format="webp"
        class="simple-image"
        :class="imageClasses"
        :style="imageStyles"
        data-classic="Hello world"
    ></NuxtImg>
</template>

<script lang="ts" setup>
import type { ICoreSimpleImage } from '~ui/types/components/CoreSimpleImage';
import { EHideOnType } from '~ui/types/enums/ComponentStyle';

const { blok } = defineProps<{ blok: ICoreSimpleImage }>();

const objectPosition = computed(() => {
    if (blok.imagePositionHorizontal && blok.imagePositionVertical) {
        return `${blok.imagePositionHorizontal}% ${blok.imagePositionVertical}%`;
    }
});

const imageStyles = computed(() => {
    return {
        objectFit: blok.imageFit || null,
        objectPosition: objectPosition.value || null,
        width: blok.width && !blok.fullWidth ? `${blok.width}px` : null,
        height: blok.height && !blok.fullWidth ? `${blok.height}px` : null,
        borderWidth: blok.borderWidth ? `${blok.borderWidth}px` : null,
        borderColor: blok.borderWidth ? blok.borderColor.color : null,
        borderRadius: blok.rounded ? `${blok.rounded}%` : null,
    };
});

const imageClasses = computed(() => {
    return {
        'w-full': blok.fullWidth,
        'mobile-only': blok.hideOn === EHideOnType.Desktop,
        'desktop-only': blok.hideOn === EHideOnType.Mobile,
    };
});
</script>

<style lang="postcss" scoped>
.simple-image {
    @apply h-auto;
    &.mobile-only {
        @apply md:hidden;
    }
    &.desktop-only {
        @apply hidden md:block;
    }
}
</style>
