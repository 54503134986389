<template>
    <nuxt-link tabindex="0" :to="getInternalLink(navItem.Link)" :target="navItem.Target" external>
        <div v-if="navItem.CustomIcon?.filename">
            <NuxtImg
                v-if="navItem.CustomIcon?.filename"
                :src="navItem.CustomIcon.filename"
                :alt="navItem.CustomIcon.filename"
                format="webp"
                loading="eager"
                height="16"
                width="16"
            />
        </div>
        <template v-else-if="navItem.Icon?.icon">
            <i v-if="navItem.Icon.icon" class="fa-solid" :class="navItem.Icon.icon"></i>
        </template>
        {{ navItem.Text }}
    </nuxt-link>
</template>

<script setup lang="ts">
import { getInternalLink } from '../../../../../../apps/_base/utils/internalLinking';

defineProps<{ navItem: any }>();
</script>

<style scoped lang="postcss"></style>
