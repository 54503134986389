<template>
    <div
        class="mobile-menu md:hidden new-header"
        :class="componentClasses"
        @touchstart="startDrag"
        @touchend="endDrag"
        @click.stop
    >
        <div class="close-button" data-cy="closeMobileMenu" @click.stop="emit('closeMobileMenu')">
            <span>×</span>
        </div>
        <div class="scroll-wrapper">
            <div class="scroll" :class="`level-${currentLevel}`">
                <ul>
                    <template v-for="(navItem, index) in blok.MobileNavigation" :key="navItem._uid">
                        <li
                            v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                            :class="navItemClasses(navItem)"
                        >
                            <LinkItem v-if="navItem.component === 'Link'" :nav-item="navItem" />
                            <LinkHtmlContent v-else-if="navItem.component === 'LinkHtmlContent'" :nav-item="navItem" />
                            <HtmlContent v-else-if="navItem.component === 'NavHtmlItem'" :nav-item="navItem" />
                            <span
                                v-else-if="navItem.component === 'Dropdown'"
                                :data-cy="`mobileMenuDropdown-${index}`"
                                :class="{ active: activeItems[navItem._uid] }"
                                @click.prevent.stop="changeLevel(2, navItem)"
                            >
                                {{ navItem.Title }}
                                <i class="fa-solid fa-angle-right"></i>
                            </span>
                        </li>
                        <hr
                            v-if="navItem.component === 'Separator'"
                            :style="`border-bottom: 1px solid ${navItem.Color.color}`"
                        />
                    </template>
                </ul>
                <template v-for="key in Object.keys(menuLevelsItems)" :key="`menu-level-${key}`">
                    <MenuLevel
                        v-if="key > 1"
                        :current-level="currentLevel"
                        :nav-item="menuLevelsItems[key]"
                        :level="parseInt(key)"
                        @change-menu-level="changeLevel"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LinkHtmlContent from '~ui/components/Header/Components/NavItem/LinkHtmlContent.vue';
import MenuLevel from '~ui/components/Header/Components/Mobile/MenuLevel.vue';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import HtmlContent from '~ui/components/Header/Components/NavItem/HtmlContent.vue';
import { EMobileMenuStyles } from '~ui/types/enums/EMobileMenuStyles';
import { isCurrentPageLink } from '~ui/utils/navigations';

const { blok, mobileMenuIsActive, mobileMenuStyle } = defineProps<{
    blok: any;
    mobileMenuIsActive: boolean;
    mobileMenuStyle: string;
    activeItems: {};
}>();
// swipe event initial values
const startX = ref(0);
const startTime = ref(0);
const minSwipeDistance = ref(50);
const minSwipeSpeed = ref(0.5);
const currentLevel = ref(0);
const menuLevelsItems = ref({});
const emit = defineEmits<{
    (e: 'closeMobileMenu'): void;
}>();

const componentClasses = computed(() => ({
    active: mobileMenuIsActive,
    'slide-left': mobileMenuStyle === EMobileMenuStyles.SLIDE_LEFT,
    'slide-right': mobileMenuStyle === EMobileMenuStyles.SLIDE_RIGHT,
}));

const startDrag = (e: any) => {
    startX.value = e.changedTouches[0].clientX;
    startTime.value = Date.now();
};
const endDrag = (e: any) => {
    const endX = e.changedTouches[0].clientX;
    const endTime = Date.now();
    const deltaX = endX - startX.value;
    const deltaTime = endTime - startTime.value;
    const speed = Math.abs(deltaX) / deltaTime;

    if (deltaX > 0 && Math.abs(deltaX) > minSwipeDistance.value && speed > minSwipeSpeed.value) {
        // Swipe right detected with sufficient distance and speed and close mobile menu
        emit('closeMobileMenu');
    }
};

const navItemClasses = (navItem: any) => ({
    'drop-down-button': navItem.Options,
    'current-page-nav-item': isCurrentPageLink(navItem),
});

const changeLevel = (level: number, activeNavItem: any) => {
    currentLevel.value = level;
    menuLevelsItems.value[level] = activeNavItem;
};

(function calculateMenuLevels(items, level) {
    menuLevelsItems.value[level] = [];
    items.forEach((item: any) => {
        if (item.component === 'Dropdown') {
            calculateMenuLevels(item.Options, level + 1);
        }
    });
})(blok.MobileNavigation, 1);
</script>

<style scoped lang="postcss">
.mobile-menu {
    @apply fixed top-0 right-0 transform translate-x-full transition-transform ease-in-out duration-[400ms] bottom-0 w-[75vw] bg-white z-50 h-[calc(100vh)] pl-4 pt-12 overflow-y-scroll;
    box-shadow: -3px -4px 20px 0 rgba(0, 0, 0, 0.1);
    &.slide-left {
        @apply -translate-x-full;
    }

    &.active {
        @apply transform translate-x-0;
    }

    .close-button {
        @apply absolute right-0 top-[10px] z-[1];

        span {
            @apply cursor-pointer flex items-center justify-center w-16 text-[2rem] font-light text-[#243845];
        }
    }

    li {
        @apply border-b border-[#adbfd1] text-[0.875rem] text-[#243845];
        &:empty {
            @apply hidden;
        }

        a,
        span {
            @apply flex items-center p-[1.032rem] pl-0 pr-0 relative;

            > i {
                @apply self-center transition-transform ease-in duration-300 absolute right-4;
            }
        }

        div {
            @apply flex items-center justify-center w-4 mr-2;

            i {
                @apply text-primary h-4;
            }
        }

        .nav-mobile-dropdown {
            @apply hidden;

            &.active {
                @apply block;
            }

            li {
                @apply pl-9;
            }
        }

        &.drop-down-button {
            @apply border-b-0;

            > a {
                @apply w-full flex items-center border-b border-[#adbfd1] pb-4;
            }
        }
    }

    &.new-header {
        @apply w-[75vw] overflow-x-hidden pt-6 pl-6;

        li {
            @apply border-none mb-5;

            div i {
                @apply text-[#293f4d];
            }

            a,
            span {
                @apply py-0 cursor-pointer;

                &:hover {
                    @apply underline;
                }
            }

            .fa-angle-right {
                @apply text-[20px];
            }

            &:first-of-type {
                a,
                span {
                    @apply pt-4;
                }
            }

            &.drop-down-button {
                @apply border-b-0;

                > a {
                    @apply border-none;
                }

                i {
                    @apply text-base text-[20px];
                }
            }
        }

        .scroll-wrapper {
            @apply flex overflow-x-hidden;

            .scroll {
                @apply inline-grid grid-cols-[repeat(3,75vw)] transition-[transform] duration-[0.6s];

                > ul > hr {
                    @apply my-5;
                }

                &.level-2 {
                    @apply translate-x-[-75vw];
                }

                &.level-3 {
                    @apply translate-x-[-150vw];
                }

                .level-wrapper,
                > ul {
                    @apply pr-[30px] pt-3;
                }

                .level-wrapper {
                    hr {
                        &.top {
                            @apply mt-6;
                        }

                        &.bottom {
                            @apply mb-3;
                        }
                    }
                }

                .back-button {
                    @apply flex gap-2 cursor-pointer;
                }

                .title {
                    @apply min-h-[48px] flex items-center text-sm font-semibold;
                }
            }
        }
    }
}
</style>
