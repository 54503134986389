import payload_plugin_v3id25CMDv from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_oWBA7WHA7R from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_C5ViuX7Wtl from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9Rn9mPCYbL from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_feCsykgv1q from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.2_terser@5_l5q7a52smqrigb5xtj3oo42poe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_6jZ7GeEMKD from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_w990r9yMnu from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vMSlj2v4pD from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SqLNMtpvWd from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QOGv456u33 from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/.nuxt/components.plugin.mjs";
import prefetch_client_KEWfjrSNdW from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_Imss2aaJLy from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.10.2_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.2_terser@5.37.0__yopuzha23aku3jlasekoffrhau/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_GmopPxAbej from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_w016SqEANS from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_3HrtL2D7Xd from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5_2o7vzjtiyt4njxn7uqxwgaazsm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_fMOyHGjvbz from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_TpPc9NuPhz from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  payload_plugin_v3id25CMDv,
  revive_payload_client_oWBA7WHA7R,
  unhead_C5ViuX7Wtl,
  router_9Rn9mPCYbL,
  _0_siteConfig_feCsykgv1q,
  payload_client_6jZ7GeEMKD,
  navigation_repaint_client_w990r9yMnu,
  check_outdated_build_client_vMSlj2v4pD,
  chunk_reload_client_SqLNMtpvWd,
  plugin_vue3_QOGv456u33,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KEWfjrSNdW,
  plugin_OHU0vggoM9,
  plugin_Imss2aaJLy,
  nuxt_plugin_GmopPxAbej,
  plugin_w016SqEANS,
  plugin_3HrtL2D7Xd,
  switch_locale_path_ssr_fMOyHGjvbz,
  i18n_TpPc9NuPhz,
  base64_2hii42QBJd
]