<template>
    <div v-on-click-outside="closeDropdown" class="input-group" @focusin="openDropdown">
        <NuxtIcon v-if="icon" :name="`ri:${icon}`" />
        <input
            :id="`${name}Input`"
            :value="inputValue"
            :class="{ 'is-error': validationErrorsCount !== 0 }"
            :name="name"
            type="search"
            :placeholder="placeholder"
            autocomplete="off"
            @input="(e: any) => (inputValue = e.target.value)"
            @focus="(e: any) => (inputValue = '')"
        />
        <ul v-if="showDropdown" class="input-dropdown">
            <template v-for="(item, index) in filteredSearch" :key="index">
                <template v-if="item[0] && isCountry(item[0].country.slug)">
                    <li class="input-dropdown-heading">
                        {{ item[0].country.name }}
                    </li>
                    <template v-for="(childItem, childIndex) in item" :key="childIndex">
                        <li class="input-dropdown-item" @click="selected = childItem.name">
                            {{ childItem.name }}
                            <span>[{{ childItem.code }}]</span>
                        </li>
                    </template>
                </template>
                <template v-else>
                    <li class="input-dropdown-item not-found">No airports were found.</li>
                </template>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
// VueUse - onClickOutside
// https://vueuse.org/core/onClickOutside/
import { vOnClickOutside } from '@vueuse/components';
import { useQuoteStore } from '~ui/../../apps/l4/store/quote'; //TODO change

const props: any = defineProps({
    name: { type: String, required: true, default: 'search' },
    placeholder: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    items: { type: Array, required: false, default: null },
    country: { type: String, required: false, default: '' },
    validationErrorsCount: { type: Number, default: 0 },
});

// Constant variable to define prop 'items'
const itemArray: any = ref(props.items);

// Constant variable to define prop 'value'
const inputValue: any = ref(props.value);

// Toggle to show/hide dropdown
const showDropdown = ref(false);

// Initialising emit events
const emit: any = defineEmits(['update:value']);

// Whenever new time is set, we want to update the variable, close dropdown and emit the data
// to parent for adding to store.
const selected: any = computed({
    get() {
        return props.value;
    },
    set(value) {
        let selected: any = value;
        props.items.forEach((itemSection: any) => {
            itemSection.forEach((item: any) => {
                if (item.name === value) selected = item;
            });
        });
        inputValue.value = value;
        emit('update:value', selected);
        closeDropdown();
    },
});

// Get country name
const { locale } = useI18n();

const swapCountry = {
    'be-nl': 'be',
    'be-fr': 'be',
    'ca-fr': 'ca',
    'ch-fr': 'ch',
};

const mappedCountry = swapCountry[locale.value] || locale.value;
// Current country need to be first in search array
const sortedByCountryItems = computed(() => {
    const countryIndex = itemArray.value.map((el) => el[0].country.slug).indexOf(mappedCountry.toUpperCase());
    if (countryIndex === -1) {
        return itemArray.value;
    }

    const copy = [...itemArray.value];
    return [copy.splice(countryIndex, 1)[0], ...copy];
});

// Computed variable to get calculate filtered items
const filteredSearch: any = computed(() => {
    const filter = inputValue.value;

    return sortedByCountryItems?.value?.map((group: any) => {
        return group
            .filter((airport: any) => {
                const name = airport?.name.toLowerCase() || '';
                const code = airport?.code.toLowerCase() || '';
                const country = airport?.country?.name?.toLowerCase() || '';
                return (
                    name.includes(filter.toLowerCase()) ||
                    code.includes(filter.toLowerCase()) ||
                    country.includes(filter.toLowerCase())
                );
            })
            .sort((airport1: any, airport2: any) => airport1.name.localeCompare(airport2.name));
    });
});

// Event to close dropdown
const closeDropdown = () => {
    showDropdown.value = false;
    nextTick(() => (inputValue.value = selected.value));
};

// Event to open dropdown
const openDropdown = () => {
    showDropdown.value = true;
};

function isCountry(country: string) {
    if (props.country) {
        if (country.toLowerCase() === getLanguageForAPI(props.country.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

// Validation
watch(
    () => props.validationErrorsCount,
    (count) => {
        showDropdown.value = count !== 0;
    }
);

// Get search value from localStorage, works only if the user has previously visited the search results page and then navigated back.
// Pinia store clears for correct airport / port / transfer filling in single entity page
onMounted(() => {
    const quoteStore = useQuoteStore();
    const airport = quoteStore.getAllData.startDestination;
    if (airport.code) {
        selected.value = airport;
        quoteStore.updateQuoteFormStartDestination({ code: '', name: '' });
    }
});
</script>

<style lang="postcss" scoped>
.input-dropdown {
    @apply absolute left-0 right-0 z-[99] top-[2.75rem] bg-white rounded max-h-[300px] overflow-y-auto;
}

.input-dropdown-heading,
.input-dropdown-item {
    @apply flex items-center justify-between p-2 border-b border-[#e9daf0] text-gray-600;
}

.input-dropdown-heading {
    @apply font-semibold;
}

.input-dropdown-item span {
    @apply text-xs;
}

.input-dropdown-item:hover {
    @apply bg-primary text-white cursor-pointer;
}

.input-group input.is-error {
    &::placeholder {
        color: #ff0000;
    }
    border: 1px solid #ff0000;
    &:focus {
        @apply border-primary;
    }
}
</style>
