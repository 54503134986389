<template>
    <div class="text-nav-item">
        <CoreSimpleRichText :blok="{ richText: navItem.content }" />
    </div>
</template>

<script setup lang="ts">
import CoreSimpleRichText from '~ui/components/CoreSimpleRichText.vue';

defineProps<{ navItem: any }>();
</script>

<style scoped lang="postcss"></style>
