<template>
    <div class="rating-stars">
        <NuxtIcon v-for="i in fullStars" :key="i" name="ri:star-fill" class="rating-star-icon full-star" />
        <NuxtIcon v-if="displayHalfStar" name="ri:star-half-fill" class="rating-star-icon half-star" />
        <NuxtIcon v-for="i in emptyStars" :key="i" name="ri:star-line" class="rating-star-icon empty-star" />
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{ value: Number }>();

const maxStarsCount = 5;

const starsRating = computed(() => {
    return Math.round((props.value as number) * 2) / 2;
});

const fullStars = computed(() => Math.floor(starsRating.value));
const displayHalfStar = computed(() => starsRating.value % 1 !== 0);
const emptyStars = computed(() => Math.floor(maxStarsCount - starsRating.value));
</script>

<style lang="postcss" scoped>
.full-star {
    @apply text-stars-900;
}
.empty-star {
    @apply text-stars-0;
}

:deep(.half-star) {
    @apply text-stars-900;
}
</style>
