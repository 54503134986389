<template>
    <UiCoreLayout v-editable="blok" class="sb-core-layout" data-test="sb-core-layout" :blok="blok" />
</template>

<script setup lang="ts">
import type { ICoreLayout } from '~ui/types/components/CoreLayout';

defineProps<{ blok: ICoreLayout }>();
</script>

<style scoped lang="postcss">
:deep(.carousel__pagination) {
    @apply mt-0;
}
:deep(.carousel__pagination-button) {
    @apply border-y-[10px] border-transparent border-solid p-0;
    &:after {
        @apply p-0 mx-2.5 w-3 h-3 bg-[#dbdcde] rounded-full;
    }
}
:deep(.carousel__pagination-button--active) {
    &:after {
        @apply bg-secondary;
    }
}
</style>
