<template>
    <div class="testimonials" :style="componentStyles" :class="{ container: !blok.fullWidth }">
        <div class="testimonials-container">
            <UiCommonStarsRating :value="blok.score" />
            <div class="review">{{ blok.review }}</div>
            <div class="reviewer">{{ blok.reviewer }}, {{ blok.location }}</div>
            <div v-if="blok.showReviewSummary" class="summary">
                <template v-if="blok.customReviewText">
                    {{ blok.customReviewText }}: <span class="review-score">{{ blok.reviewScore }}%</span> ({{
                        blok.totalReviews
                    }})
                </template>
                <template v-else>
                    {{ defaultReviewsText }}:
                    <span class="review-score">{{ blok.reviewScore }}%</span>
                    ({{ blok.totalReviews }})
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { buildBackground } from '~ui/utils/styleBuilder';
import type { IAirportEntity } from '~ui/types';
import type { ITestimonial } from '~ui/types/components/Jetpark/Testimonial';

const { blok, airport } = defineProps<{ blok: ITestimonial; airport?: IAirportEntity }>();
const background = buildBackground(blok.background?.[0]);
const route = useRoute();

const componentStyles = computed(() => ({
    ...background,
}));
const { t } = useI18n();
const defaultReviewsText = computed(() => {
    let locationText = airport?.content?.name;
    if (route.params?.slug?.includes(t('testimonials.slugs.airportParking'))) {
        locationText = `${locationText} ${t('testimonials.locationTypeTexts.airportParking')}`;
    }

    return t('testimonials.reviews', { location: locationText });
});
</script>

<style lang="postcss" scoped>
.testimonials {
    @apply relative text-black m-auto;

    .testimonials-container {
        @apply text-center px-2 pt-16 pb-12 max-md:px-12;

        & > * {
            @apply px-12;
        }

        &:before {
            @apply content-[''] w-[30px] h-[30px] absolute top-16 left-4 bg-no-repeat;
            background-image: url('~/assets/images/open-quote.svg');
        }

        &:after {
            @apply content-[''] w-[30px] h-[30px] absolute bottom-20 right-4 bg-no-repeat;
            background-image: url('~/assets/images/close-quote.svg');
        }

        .review,
        .reviewer,
        .summary {
            @apply py-2;
        }

        .reviewer {
            @apply font-bold;
        }

        .review-score {
            @apply text-primary;
        }

        :deep(.rating-stars) {
            @apply flex justify-center pb-4;
            .rating-star-icon {
                @apply px-1 h-[25px];
                &.full-star {
                    @apply text-[#EC008C];
                }

                &.half-star {
                    --fa-primary-color: #da328a;
                }
            }
        }
    }
}
</style>
