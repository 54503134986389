<template>
    <header>
        <div class="container mx-auto" :class="{ '!max-w-full': blok.FullWidth, 'is-logged-in': isLoggedIn }">
            <Logo :logo="global?.Logo" />
            <nav class="nav">
                <ul class="nav-row hidden md:flex">
                    <template v-for="(navItem, index) in blok.Navigation" :key="navItem._uid">
                        <li
                            v-if="displayNavItem(navItem)"
                            class="nav-row-item relative"
                            :data-cy="`deskTopDropDownDropdown-${index}`"
                            :class="getNavItemClasses(navItem, index)"
                            @click.stop="(e: any) => onClickNavItem(e, navItem)"
                        >
                            <LinkItem v-if="navItem.component === 'Link'" :nav-item="navItem" />
                            <Dropdown
                                v-else-if="navItem.component === 'Dropdown'"
                                :nav-item="navItem"
                                :active-items="activeItems"
                                @mouseenter.stop="(e: any) => toggleDropdown(e, navItem, false, false, true)"
                                @mouseleave.stop="(e: any) => toggleDropdown(e, navItem, false, false, true)"
                                @keyup.enter.stop="(e: any) => toggleDropdown(e, navItem, true, true)"
                                @toggle-dropdown="toggleDropdown"
                                @on-click-nav-item="onClickNavItem"
                            />
                            <LinkHtmlContent v-else-if="navItem.component === 'LinkHtmlContent'" :nav-item="navItem" />
                            <HtmlContent v-else-if="navItem.component === 'NavHtmlItem'" :nav-item="navItem" />
                        </li>
                    </template>
                </ul>
                <MobileMenu
                    :mobile-menu-is-active="mobileMenuIsActive"
                    :active-items="activeItems"
                    :blok="blok"
                    :mobile-menu-style="blok.mobileMenuStyle"
                    @close-mobile-menu="closeMobileMenu"
                />
            </nav>
            <MobileMenuTogglerBtn
                :mobile-menu-is-active="mobileMenuIsActive"
                :menu-icon-component="menuIconComponent"
                @open-mobile-menu="openMobileMenu"
                @close-mobile-menu="closeMobileMenu"
            />
        </div>

        <transition name="custom-fade">
            <div v-if="mobileMenuIsActive" class="mobile-nav-bg hidden" @click="closeMobileMenu"></div>
        </transition>
    </header>
</template>

<script setup lang="ts">
/*
    The refactoring item list:
        1 Dropdown style for mobile
        2 Get rid of oldstyle, maybe just remove because it doesn't exist in SB
        3 Get rid of MenuIcon Component inside mobile menu list
        4 Get rid of ShowOnLogin, replace with displayOption: always(should be default), LoggedInOnly, NonLoggedIn.
          Now we just dont display the nav item which has modalId.
        5 Toggle sub dropdown behavior
        5 Add unit test
        6 Add types for vars
 */
import LinkHtmlContent from '~ui/components/Header/Components/NavItem/LinkHtmlContent.vue';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import Dropdown from '~ui/components/Header/Components/NavItem/Dropdown.vue';
import MobileMenuTogglerBtn from '~ui/components/Header/Components/Mobile/MobileMenuTogglerBtn.vue';
import HtmlContent from '~ui/components/Header/Components/NavItem/HtmlContent.vue';
import Logo from '~ui/components/Header/Components/Logo.vue';
import MobileMenu from '~ui/components/Header/Components/Mobile/MobileMenu.vue';
import { useAuthStore } from '~ui/../../apps/_base/store/auth';
import { useModalStore } from '~ui/../../apps/_base/store/modal';
import { isCurrentPageLink } from '~ui/utils/navigations';
import { getInternalLink } from '~ui/../../apps/_base/utils/internalLinking';

const { blok, global } = defineProps({ blok: Object, global: Object });
const emit = defineEmits(['logout']);

const modalStore = useModalStore();
const authStore = useAuthStore();
const activeItems = ref({});
const cachedId = ref(null);
const mobileMenuIsActive = ref(false);

const isLoggedIn = computed(() => !!(authStore.$state.id && authStore.$state.role && authStore.$state.csrf));

const displayNavItem = (navItem: any) => {
    /*
        if item has "show on login" and user is logged in - display item
        if item does not have "show on login" and (it has modal it and user is not logged in) - display item
        if item does not have "show on login" - display item
        need to make it easier to understand after refactoring point 4 (about get rid of ShowOnLogin)
     */
    return (
        (navItem.ShowOnLogin && isLoggedIn.value && !navItem.ModalId) ||
        (!navItem.ShowOnLogin && !navItem.ModalId) ||
        (!isLoggedIn.value && !!navItem.ModalId)
    );
};

const getNavItemClasses = (navItem, index) => {
    return [
        `dropdown-${index}`,
        {
            dropdown: navItem.Options,
            active: isActive(navItem) && !navItem.Link?.url,
            'current-page-nav-item': isCurrentPageLink(navItem.Link),
            'is-single-button': navItem.Link?.url || navItem.ModalId,
            'modal-button': navItem.ModalId,
            'rich-text-menu-item': navItem.component === 'LinkHtmlContent',
        },
    ];
};

const toggleDropdown = (e: any, navItem: any, accessibility = false, innerDropDown = false, main = false) => {
    if (navItem.component !== 'Dropdown') {
        return;
    }

    toggleMenu(e, navItem, accessibility, innerDropDown, main);
};

const onClickNavItem = (e: any, navItem: any) => {
    if (getInternalLink(navItem?.Link) === '/logout') {
        e.preventDefault();
        emit('logout');
        return;
    }

    toggleMenu(e, navItem);
};

const toggleMenu = (e: any, navItem: any, accessibility = false, innerDropDown = false, main = false) => {
    if (navItem.ModalId) {
        e.preventDefault();
        modalStore.toggleModal(navItem.ModalId, true);
        closeAllDropDowns();
        closeMobileMenu();
    } else {
        // if we hover on the inner dropdown in dropdown
        if (innerDropDown) {
            // if we have cached value, we toggle previous value to false for close previous dropdown
            if (cachedId.value) {
                activeItems.value[cachedId.value] = false;
            }
            // open current inner dropdown
            activeItems.value[navItem._uid] = true;
            // write cached value for current inner dropdown
            cachedId.value = navItem._uid;
            return;
        }

        // on-leave we close dropdown
        if (activeItems.value[navItem._uid]) {
            activeItems.value[navItem._uid] = false;
            return;
        }

        // on click on another dropdown we close previous
        //TODO get rid of oldstyle, seems it is not needed
        if (blok.OldStyle) {
            Object.keys(activeItems.value).forEach((key) => {
                activeItems.value[key] = key === navItem._uid;
            });
        }
        // close all dropdowns
        if ((accessibility && !innerDropDown) || main) {
            closeAllDropDowns();
        }
        // set active items field activeItems.value = {some uid of nav item: true}
        activeItems.value[navItem._uid] = true;
    }
};

// close all dropdowns
const closeAllDropDowns = (e = null) => {
    if (e?.Target.dataset?.dropdown) {
        return;
    }
    Object.keys(activeItems.value).forEach((key) => {
        activeItems.value[key] = false;
    });
};

const closeMobileMenu = () => {
    // Add delay for click events to register
    setTimeout(() => {
        mobileMenuIsActive.value = false;
    }, 100);
    // Add delay for waiting animation done
    setTimeout(() => {
        modalStore.enableScroll();
    }, 600);
};

const openMobileMenu = () => {
    mobileMenuIsActive.value = true;
    modalStore.disableScroll();
};

const isActive = (navItem) => activeItems.value[navItem._uid];

// class of the icon of font-awesome from the backend
const menuIconComponent = computed(() => blok.MobileNavigation.find((item) => item.component === 'MenuIcon'));
</script>

<style scoped lang="postcss">
header {
    @apply bg-gray-400 text-gray-900;

    .container {
        @apply flex flex-wrap items-center justify-between;
    }

    nav {
        @apply h-full;

        .nav-row {
            @apply items-center h-full;

            .nav-row-item {
                @apply h-full whitespace-nowrap flex items-center;

                &.active {
                    .i-ri\:arrow-down-s-line {
                        @apply rotate-180;
                    }
                }

                a,
                span {
                    @apply flex items-center h-full px-4 text-[#495057] cursor-pointer;
                }

                &.rich-text-menu-item {
                    @apply h-auto;

                    a {
                        @apply block pt-2.5 pb-[5px] px-[15px] h-auto;
                        :deep(p) {
                            @apply m-0 text-inherit;
                        }
                    }
                }
            }

            .mobile-menu {
                display: block;
            }
        }

        :deep(.nav-dropdown) {
            @apply grid absolute min-w-[10rem] bg-gray-400 z-[100];

            &.right {
                @apply left-auto right-0;
            }

            &.left {
                @apply left-0 right-auto;
            }

            &.center {
                @apply -translate-x-2/4 left-2/4;
            }

            a {
                @apply inline-block py-[0.25rem];

                &:hover {
                    @apply bg-gray-600;
                }
            }
        }
    }
}

.mobile-nav-bg {
    @apply fixed top-0 right-0 left-0 bottom-0 md:hidden z-[4] bg-[#293338] opacity-60;
}

.mobile-fall-menu {
    @apply hidden z-50 md:hidden absolute inset-x-0;
    background: inherit;

    &.active {
        @apply max-md:block;
    }

    li {
        a {
            @apply px-0.5 py-8 text-center block;
        }

        &.rich-text-menu-item {
            a {
                @apply block pt-2.5 pb-[5px] px-[15px] h-auto;
                :deep(p) {
                    @apply m-0 text-inherit;
                }
            }
        }
    }

    .level-wrapper {
        li {
            a {
                @apply px-[15px] py-[7px];
            }
        }
    }
}
</style>
