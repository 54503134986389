<template>
    <div class="ui-simple-rich-text" data-test="ui-simple-rich-text" :style="componentStyles">
        <div class="rich-text" :class="`text-${blok.textAlignment}`">
            <rich-text :content="blok.richText" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { VNode } from 'vue';
import { createTextVNode, h } from 'vue';
import {
    BlockTypes,
    richTextResolver,
    type StoryblokRichTextNode,
    type StoryblokRichTextOptions,
} from '@storyblok/richtext';

import type { ICoreSimpleRichText } from '~ui/types/components/CoreSimpleRichText';

const { blok } = defineProps<{ blok: ICoreSimpleRichText }>();

const options: StoryblokRichTextOptions<VNode> = {
    renderFn: h,
    textFn: createTextVNode,
    resolvers: {
        [BlockTypes.COMPONENT]: (node: StoryblokRichTextNode<VNode>) => {
            return node?.attrs?.body.map((blok) => {
                switch (blok.component) {
                    case 'OTCookieButton': {
                        return h(
                            'p',
                            {
                                innerHTML: `<button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>`,
                            },
                            node.children
                        );
                    }
                    case 'OTCookieTables': {
                        return h('div', { id: 'ot-sdk-cookie-policy' }, node.children);
                    }
                    case 'CheckmarkList': {
                        const cssStyles = `
                        --checkmark-size: ${blok?.checkmarkSize}px;
                        --checkmark-list-font-size: ${blok?.fontSize}px;
                        `;
                        let listItems = '';
                        blok?.listItems?.forEach((item) => {
                            listItems += `<li>
                            <NuxtIcon name="ri:circle-check-line" />
                            ${item.text}
                        </li>`;
                        });
                        return h(
                            'ul',
                            { class: 'richtext-checkmark-list', style: cssStyles, innerHTML: listItems },
                            node.children
                        );
                    }
                    default: {
                        return 'Resolver not defined: ' + blok.component;
                    }
                }
            });
        },
    },
};

const richText = (content: any) => {
    return richTextResolver<VNode>(options).render(content.content);
};

const componentStyles = computed(() => ({
    'padding-top': blok.paddingTop ? `${blok.paddingTop}px` : null,
    'padding-right': blok.paddingRight ? `${blok.paddingRight}px` : null,
    'padding-bottom': blok.paddingBottom ? `${blok.paddingBottom}px` : null,
    'padding-left': blok.paddingLeft ? `${blok.paddingLeft}px` : null,
}));

[
    { type: 'paragraph', content: [{ text: 'Hello test', type: 'text' }] },
    {
        type: 'blok',
        attrs: {
            id: '74aefb7b-925e-45f0-b87e-6d0cd3896e46',
            body: [
                {
                    _uid: 'i-7b4b500c-fa35-4b25-bdb0-f6ab45970721',
                    component: 'OTCookieButton',
                    _editable:
                        '\u003c!--#storyblok#{"name": "OTCookieButton", "space": "249644", "uid": "i-7b4b500c-fa35-4b25-bdb0-f6ab45970721", "id": "589895308"}--\u003e',
                },
            ],
        },
    },
];
</script>

<style scoped lang="postcss">
.ui-simple-rich-text {
    &:deep(.css-float-left) {
        @apply float-left pr-2.5;
    }
    &:deep(.css-float-right) {
        @apply float-right pl-2.5;
    }
    &:deep(.css-text-sm) {
        @apply text-sm;
    }
    &:deep(.css-text-md) {
        @apply text-base;
    }
    &:deep(.css-text-lg) {
        @apply text-lg;
    }
    &:deep(.css-text-xl) {
        @apply text-xl;
    }
    &:deep(.css-text-2xl) {
        @apply text-2xl;
    }
    &:deep(.css-text-3xl) {
        @apply text-3xl;
    }
    &:deep(.css-text-4xl) {
        @apply text-4xl;
    }
    &.text-left-align {
        @apply text-left;
    }
    &.text-center-align {
        @apply text-center;
    }
    &.text-right-align {
        @apply text-right;
    }
    &:deep(ul) {
        &:has(.css-list-checkmarks) {
            @apply list-none;
        }
        li {
            span:first-child.css-list-checkmarks:after {
                @apply content-["\2714"] block absolute -left-5 -top-1 text-base;
                font-family: 'Font Awesome 6 Duotone';
            }

            span:first-child.css-list-checkmarks {
                @apply relative pl-2;
            }
        }
    }

    :deep(.richtext-checkmark-list) {
        font-size: var(--checkmark-list-font-size);
        @apply ml-0 list-none;
        .iconify {
            font-size: var(--checkmark-size);
            margin-right: calc(var(--checkmark-size) / 2);
        }
        li {
            @apply mb-2.5 flex flex-nowrap items-start;
        }
    }
}
</style>
