<template>
    <nuxt-link to="/" class="logo" external>
        <NuxtImg
            v-if="logo?.filename"
            :src="logo.filename"
            :title="logo.title"
            :alt="logo.alt"
            provider="storyblok"
            format="webp"
            loading="eager"
            densities="x1"
        />
    </nuxt-link>
</template>

<script setup lang="ts">
import type { IAssetImage } from '~ui/types/components/AssetImage';

defineProps<{ logo?: IAssetImage }>();
</script>

<style scoped lang="postcss">
.logo {
    @apply h-10 px-4;

    img {
        @apply h-full;
    }
}
</style>
