import { useAuthStore } from '../../_base/store/auth';

export function $publicApi<T>(request: Parameters<typeof $fetch<T>>[0], opts?: Parameters<typeof $fetch<T>>[1]) {
    const authStore = useAuthStore();

    return $fetch<T>(`${process.dev ? '/proxy' : ''}/${request}`, {
        ...opts,
        headers: {
            // @ts-ignore
            'X-CSRF-TOKEN': authStore.csrf,
            ...opts?.headers,
        },
    });
}

export function $privateApi<T>(request: Parameters<typeof $fetch<T>>[0], opts?: Parameters<typeof $fetch<T>>[1]) {
    // Constant variable for access to Runtime Config
    const config = useRuntimeConfig();

    return $fetch<T>(`${config.EXTRANET_API_URL}/${request}`, {
        ...opts,
        headers: {
            Authorization: `Bearer ${config.EXTRANET_API_TOKEN}`,
            ...opts?.headers,
        },
        onRequestError: (error) => {
            console.error('Private API Request Error:', `${config.EXTRANET_API_URL}/${request}`);
            console.error(error);
        },
        onResponseError: (error) => {
            console.error('Private API Response Error:', `${config.EXTRANET_API_URL}/${request}`);
            console.error(error);
        },
    });
}

export async function $resolvedLinkComponent<T>(uuid: string) {
    const storyblokApiInstance = useStoryblokApi();
    const config = useRuntimeConfig();
    const { locale } = useI18n();

    const { data } = await useAsyncData(
        uuid,
        async () =>
            await storyblokApiInstance.getStory(uuid, {
                version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
                language: locale.value,
                find_by: 'uuid',
                resolve_links: 'url',
            })
    );
    const resolvedContent = computed(() => data?.value?.data?.story?.content as T);
    return resolvedContent;
}
