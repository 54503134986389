export default {
    theme: {
        fontFamily: {
            sans: ['Open Sans', 'sans-serif'],
        },
        extend: {
            colors: {
                primary: {
                    DEFAULT: '#da328a',
                },
                secondary: {
                    DEFAULT: '#fba3d5',
                },
                tertiary: {
                    DEFAULT: '#f6d9e5',
                },
                gray: {
                    DEFAULT: '#656565',
                },
            },
        },
    },
};
