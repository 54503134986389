import { default as _91_46_46_46slug_93LbJ1kl8KqZMeta } from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as healthcznIXIy9SDMeta } from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue?macro=true";
import { default as component_45stubcxO38gohZgMeta } from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcxO38gohZg } from "/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_fth6i7ujt3eoa7uzsbkmpwxere/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1103264159/src/bitbucket.org/magusa/cavu-workspaces/apps/jetpark/pages/health.vue")
  },
  {
    name: component_45stubcxO38gohZgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubcxO38gohZg
  }
]