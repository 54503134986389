<template>
    <UiCoreSimpleImage v-editable="blok" :blok="blok" class="sb-core-simple-image" data-test="sb-core-simple-image" />
</template>

<script setup lang="ts">
import type { ICoreSimpleImage } from '~ui/types/components/CoreSimpleImage';

defineProps<{ blok: ICoreSimpleImage }>();
</script>

<style scoped lang="postcss"></style>
