<template>
    <div class="jetpark-searchbox">
        <h2>Search for Airport Parking</h2>
        <div class="searchbox-wrapper">
            <form action="/uk/search-result" method="POST" class="form" @submit.prevent="submitForm">
                <input name="Quote.Itinerary.Location_" :value="formData.destination.name" type="hidden" />
                <input name="Itinerary.LocationCode" :value="formData.destination.code" type="hidden" />
                <input v-if="terminals.length" name="Itinerary.TerminalId" :value="formData.terminal" type="hidden" />
                <input
                    name="Quote.Itinerary.Dates.From.Date_local"
                    :value="new Date(formData.dateDep.date).toLocaleDateString(localeObj.iso)"
                    type="hidden"
                />
                <input
                    name="Itinerary.Dates.From.Date"
                    :value="new Date(formData.dateDep.date).toISOString().split('T')[0]"
                    type="hidden"
                />
                <input name="Itinerary.Dates.From.Time" :value="formData.dateDep.time" type="hidden" />

                <input
                    name="Quote.Itinerary.Dates.To.Date_local"
                    :value="new Date(formData.dateRep.date).toLocaleDateString(localeObj.iso)"
                    type="hidden"
                />
                <input
                    name="Itinerary.Dates.To.Date"
                    :value="new Date(formData.dateRep.date).toISOString().split('T')[0]"
                    type="hidden"
                />
                <input name="Itinerary.Dates.To.Time" :value="formData.dateRep.time" type="hidden" />
                <input name="Itinerary.DiscountCode" :value="formData.discountCode" type="hidden" />

                <div class="dropdown-search input-group-container">
                    <LazyUiSearchboxComponentsDestinationDropdownSearchInput
                        icon="flight-takeoff-fill"
                        name="search"
                        placeholder="Type Departure Airport"
                        :items="groupedItems"
                        :value="formData.destination.name"
                        :validation-errors-count="validationErrorsCount"
                        @update:value="(value: any) => updateDestination(value)"
                    />
                </div>
                <LazyUiSearchboxComponentsSimpleSelect
                    v-if="terminals.length"
                    name="terminal"
                    title=""
                    :options="terminals"
                    :value="formData.terminal"
                    class="terminals"
                />
                <LazyUiSearchboxComponentsDateTimePicker
                    v-model:value="formData.dateDep"
                    name="departure_date"
                    :date-picker-title="t('forms.shuttles.selectDepartureDateTitle')"
                    :validation="v$.dateDep"
                    :min-time="minFromTime"
                    class="departure-date"
                    @update:value="(value: any) => updateDepartDateTime(value)"
                />
                <LazyUiSearchboxComponentsDateTimePicker
                    v-model:value="formData.dateRep"
                    name="arrival_date"
                    :date-picker-title="t('forms.shuttles.selectArrivalDateTitle')"
                    :validation="v$.dateRep"
                    :min-time="minToTime"
                    class="arrival-date"
                    @update:value="(value: any) => updateReturnDateTime(value)"
                />
                <LazyUiSearchboxComponentsSimpleInput
                    name="discount_code"
                    placeholder="Discount Code"
                    title=""
                    :value="formData.discountCode"
                    :validation="v$.discountCode"
                    class="discount-code"
                    @update:value="(value: any) => updateDiscountCode(value)"
                />
                <div class="input-group-container submit col-span-2 xl:col-start-8 xl:col-end-10 order-7">
                    <button type="submit">
                        <template v-if="!loading">Get your quote</template>
                        <i v-else class="fa-solid fa-arrows-rotate fa-spin"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { useQuoteStore } from '~ui/../../apps/l4/store/quote';
import { differenceInDays } from '~ui/../../apps/l4/utils/helpers';
import { required, minLength, helpers } from '@vuelidate/validators';

const { terminals } = defineProps<{ terminals: any }>();
const { t, locales, locale } = useI18n();
// Constant variables for access to Pinia stores
const quoteStore = useQuoteStore();
// Const variable for access to runtime config
const config: any = useRuntimeConfig();
const loading = ref(false);

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);

// Validation
const validationErrorsCount = ref(0);
const formData: any = ref({
    destination: quoteStore.getAllData.startDestination,
    dateDep: {
        date: new Date(quoteStore.getAllData.fromDate),
        time: quoteStore.getAllData.fromTime,
    },
    dateRep: {
        date: new Date(quoteStore.getAllData.toDate),
        time: quoteStore.getAllData.toTime,
    },
    discountCode: quoteStore.getAllData.discount,
    terminal: quoteStore.getAllData.terminal || terminals?.[0]?.value,
});

const rules = computed(() => {
    return {
        destination: {
            required: helpers.withMessage('Please enter destination', required),
            minLength: minLength(3),
        },
        dateDep: {
            date: {
                required: helpers.withMessage(t('forms.shuttles.errors.departDateRequired'), required),
            },
            time: {
                required: helpers.withMessage(t('forms.shuttles.errors.departTimeRequired'), required),
            },
        },
        dateRep: {
            date: {
                required: helpers.withMessage(t('forms.shuttles.errors.returnDateRequired'), required),
            },
            time: {
                required: helpers.withMessage(t('forms.shuttles.errors.returnTimeRequired'), required),
            },
        },
    };
});

const v$ = useVuelidate(rules, formData);

let groupedItems: unknown[] = [];

const airportDropdownList: any = await useAsyncStoryblok(`l4/global/dropdowns/airports`, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: 'en',
    resolve_relations: ['AirportList.country'],
}).then((data) => {
    const airports = [];
    data.value?.content?.List.find((countryAirports) => countryAirports.country?.content?.code === 'UK')?.List.map(
        (airport) => {
            airports.push({
                code: airport?.code,
                name: airport?.name,
                country: {
                    slug: 'UK',
                    name: 'United Kingdom',
                },
            });
        }
    );

    return airports.filter(
        (item, index, array) => index === array.findIndex((findItem) => findItem?.code === item?.code)
    );
});

let filteredResults = airportDropdownList;

if (config?.public?.STORYBLOK_VERSION === 'published') {
    filteredResults = filteredResults.filter((item) => {
        return item.code !== 'PTE';
    });
}

// Re-arrange the areas to be a child of their countries
groupedItems = Object.values(groupItemBy([...new Set(filteredResults)], 'country.slug')).sort((arr1, arr2) =>
    arr1[0].country.name.localeCompare(arr2[0].country.name)
);

function groupItemBy(array: any[], property: string) {
    const hash: { [key: string]: any } = {};
    const props = property.split('.');
    for (let i = 0; i < array.length; i++) {
        const key: string = props.reduce((acc, prop) => {
            return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
    }
    return hash;
}

const updateDestination = (val: any) => {
    formData.value.destination = val;
    quoteStore.updateQuoteFormStartDestination(val);
    validationErrorsCount.value = 0;
};
const updateDiscountCode = (val: string) => {
    formData.value.discountCode = val;
    quoteStore.updateQuoteFormDiscount(val);
};
const updateDepartDateTime = (value) => {
    quoteStore.updateQuoteFormFrom(new Date(value.date || quoteStore.getAllData.fromDate), value.time || '12:00');
    formData.value.dateDep.time = value.time || '12:00';
    formData.value.dateDep.date = new Date(quoteStore.getAllData.fromDate);
};
const updateReturnDateTime = (value) => {
    quoteStore.updateQuoteFormTo(new Date(value.date || quoteStore.getAllData.toDate), value.time || '12:00');
    formData.value.dateRep.time = value.time || '12:00';
    formData.value.dateRep.date = new Date(value.date || quoteStore.getAllData.toDate);
};

const minToTime = computed(() => {
    const minDate = new Date(new Date(formData.value.dateDep.date).toDateString());
    const toDate = new Date(new Date(formData.value.dateRep.date).toDateString());
    if (differenceInDays(minDate, toDate) > 0) {
        return '';
    }

    return formData.value.dateDep.time;
});

const minFromTime = computed(() => {
    const fromDate = new Date(new Date(formData.value.dateDep.date).toDateString());
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (differenceInDays(today, fromDate) > 0) {
        return '';
    }

    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
});

const submitForm = (e) => {
    loading.value = true;
    v$.value.$validate();
    if (!formData.value.destination.code) {
        validationErrorsCount.value++;
    }
    if (!v$.value.$error) {
        e.target.submit();
    }

    loading.value = false;
};
</script>
<style scoped lang="postcss">
.jetpark-searchbox {
    @apply bg-[#fba3d5] px-6 py-4 border border-[#b80061] border-solid;
    h2 {
        @apply text-lg text-white font-bold pb-2;
    }
    .form {
        @apply flex flex-wrap gap-y-4;
        .dropdown-search {
            @apply md:order-none md:flex-[0_0_80%];
        }
        .discount-code {
            @apply md:order-1 md:flex-[0_0_20%] md:pl-4;
            input {
                @apply pl-4;
            }
        }
        .departure-date {
            @apply md:order-2 md:flex-[0_0_40%] md:pr-2;
            :deep(.dates) {
                @apply justify-start;
            }
        }
        .arrival-date {
            @apply md:order-3 md:flex-[0_0_40%] md:pl-2;
            :deep(.dates) {
                @apply justify-start;
            }
        }
        .submit {
            @apply md:order-4 md:flex-[0_0_20%] md:pl-4;
            button {
                @apply w-full px-2 py-2.5 bg-[#da328a] border-solid border-[#b80061] text-lg text-white font-bold;
                border-width: 0.8px;
            }
        }

        &:has(.terminals) {
            .dropdown-search {
                @apply md:flex-[0_0_60%];
            }
            :deep(.terminals) {
                @apply md:flex-[0_0_20%] md:order-1 md:pl-4;
                select {
                    @apply w-full h-12 px-4 bg-white;
                    @apply rounded-br-none rounded-t-none rounded-bl-none;
                    @apply relative;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                i {
                    @apply left-auto right-3 top-3 z-[1] text-black text-base;
                    &:before {
                        @apply content-["\f078"];
                    }
                }
            }
            .discount-code {
                @apply md:order-2;
            }
            .departure-date {
                @apply md:order-3;
            }
            .arrival-date {
                @apply md:order-4;
            }
            .submit {
                @apply md:order-5;
            }
        }
    }
    :deep(#searchInput) {
        @apply pl-14 bg-white;
        @apply rounded-br-none rounded-t-none rounded-bl-none;
        -webkit-appearance: none;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }
    :deep(.input-group-container) {
        @apply w-full;
        &.discount-code {
            input {
                @apply pl-4;
            }
        }
        .input-group {
            @apply relative;
            .iconify {
                @apply absolute left-4 top-[0.7rem] h-6 text-[#e1008a];
                &[data-icon='xmark'] {
                    @apply font-normal;
                }
            }
        }
        .input-group-title {
            @apply hidden;
        }
        .tooltip {
            @apply hidden;
        }
        input {
            @apply w-full h-12 border border-solid border-[#d5d6d7] pl-12 text-base;
        }
    }

    :deep(.dates-container) {
        @apply max-md:w-full;
        .dates {
            select {
                @apply w-full h-full pl-12 border border-solid border-[#d5d6d7] bg-white;
            }

            [data-icon='sort'].input-icon-append {
                @apply hidden;
            }

            &.open {
                & + .vc-popover-content-wrapper {
                    @apply max-md:!translate-x-0 max-md:!translate-y-0 max-md:!top-14;

                    .calendar-header {
                        @apply hidden;
                    }
                    .vc-pane-header-wrapper {
                        @apply top-0;
                    }
                    .vc-weekdays {
                        @apply text-sm border-y-[#ededed] border-t border-solid border-b py-6;
                    }
                    .vc-highlights {
                        @apply rounded-[20px];
                    }
                }

                &:before {
                    @apply max-md:bg-white max-md:text-[#447bbe] max-md:text-base max-md:font-medium;
                }

                .close {
                    @apply max-md:text-black;
                }
            }
        }

        .vc-day-content.vc-disabled {
            @apply cursor-default;

            &:hover {
                @apply bg-transparent;
            }
            &:focus-within {
                @apply border-[initial];
            }
        }
    }

    :deep(.vc-primary) {
        --vc-accent-50: #da328a;
        --vc-accent-100: #da328a;
        --vc-accent-200: #da328a;
        --vc-accent-300: #da328a;
        --vc-accent-400: #da328a;
        --vc-accent-500: #da328a;
        --vc-accent-600: #da328a;
        --vc-accent-700: #da328a;
        --vc-accent-800: #da328a;
        --vc-accent-900: #da328a;
    }

    :deep(.dropdown-search) {
        .input-dropdown {
            @apply top-12;
        }
        .input-dropdown-heading {
            @apply text-white bg-[#da328a];
        }
        .input-dropdown-item {
            &:hover {
                @apply text-white bg-[#fba3d5];
            }
            &.not-found {
                @apply justify-center;
                &:hover {
                    @apply bg-white text-[#4b5563];
                }
            }
        }
    }
}
</style>
