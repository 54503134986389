export const useQuoteStore = defineStore('quoteStore', {
    state: () => ({
        data: {
            return: false,
            startDestination: {
                code: '',
                name: '',
            },
            endDestination: {
                code: '',
                name: '',
            },
            fromDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000), // defaults to (7) days from now
            fromTime: '12:00',
            toDate: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000), // defaults to (14) days from now
            toTime: '12:00',
            passengers: {
                total: 1,
                adult: 1,
                children: 0,
                infants: 0,
            },
            discount: '',
            terminal: '',
        },
    }),
    getters: {
        getAllData: (state) => state.data,
    },
    actions: {
        updateQuoteForm(value: QuoteForm) {
            this.data = value;
        },

        updateQuoteFormReturn(value: boolean) {
            this.data.return = value;
        },

        updateQuoteFormStartDestination(destination) {
            this.data.startDestination = destination;
        },

        updateQuoteFormEndDestination(destination) {
            this.data.endDestination = destination;
        },

        updateQuoteFormFrom(date: Date, time: string) {
            this.data.fromDate = date;
            this.data.fromTime = time;
        },

        updateQuoteFormTo(date: Date, time: string) {
            this.data.toDate = date;
            this.data.toTime = time;
        },

        updateQuoteFormPassengers(passengers: any) {
            this.data.passengers = {
                adult: passengers.adult,
                children: passengers.children,
                infants: passengers.infants,
                total: passengers.adult + passengers.children + passengers.infants,
            };
        },

        updateQuoteFormDiscount(discount: string) {
            this.data.discount = discount;
        },

        updateQuoteFormTerminal(terminal: string) {
            this.data.terminal = terminal;
        },
    },

    persist: true,
});
