<template>
    <div class="dropdown-item-content" tabindex="0">
        <span v-if="navItem.Options">{{ navItem.Title }}</span>
        <NuxtIcon name="ri:arrow-down-s-line" />
        <transition name="quick-fade">
            <ul v-show="isActive" class="nav-dropdown" :class="navItem.Direction">
                <template v-for="dropdownItem in navItem.Options" :key="dropdownItem._uid">
                    <li
                        class="nav-dropdown-item"
                        :class="{ 'current-page-nav-item': isCurrentPageLink(dropdownItem.Link) }"
                        @click.stop="(e: any) => onClickNavItem(e, dropdownItem)"
                    >
                        <LinkItem v-if="dropdownItem.component === 'Link'" :nav-item="dropdownItem" />
                        <Dropdown
                            v-else-if="navItem.component === 'Dropdown'"
                            :nav-item="dropdownItem"
                            :active-items="activeItems"
                            class="sub-dropdown"
                            @mouseenter.stop="(e: any) => toggleDropdown(e, dropdownItem, false, true, false)"
                            @keyup.enter.stop="(e: any) => toggleDropdown(e, dropdownItem, true, true, false)"
                            @toggle-dropdown="toggleDropdown"
                            @on-click-nav-item="onClickNavItem"
                        />
                    </li>
                </template>
            </ul>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { isCurrentPageLink } from '~ui/utils/navigations';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import Dropdown from '~ui/components/Header/Components/NavItem/Dropdown.vue';

const { navItem, activeItems } = defineProps<{ navItem: any; activeItems: {} }>();
const emit = defineEmits<{
    (
        e: 'toggleDropdown',
        event: any,
        navItem: any,
        accessibility: boolean,
        innerDropDown: boolean,
        main: boolean
    ): void;
    (e: 'onClickNavItem', event: any, navItem: any): void;
}>();

const isActive = computed(() => activeItems[navItem._uid]);

const toggleDropdown = (e: any, navItem: any, accessibility = false, innerDropDown = false, main = false) => {
    emit('toggleDropdown', e, navItem, accessibility, innerDropDown, main);
};

const onClickNavItem = (e: any, navItem: any) => {
    emit('onClickNavItem', e, navItem);
};
</script>

<style scoped lang="postcss"></style>
