<template>
    <div>
        <UiHeroCardLayoutHeader
            v-editable="blok"
            :blok="blok"
            class="ui-hero-card-layout-header"
        ></UiHeroCardLayoutHeader>
    </div>
</template>

<script setup lang="ts">
import type { IHeroCardLayoutHeader } from '~ui/types/components/HeroCardLayout/LayoutHeader';

defineProps<{ blok: IHeroCardLayoutHeader }>();
</script>

<style lang="postcss" scoped>
:deep(.ui-hero-card-layout-header) {
    @apply px-5 sm:px-0 py-[28px] md:pb-24 lg:pt-20 lg:pb-[88px];
}
</style>
